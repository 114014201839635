
import { Drawer } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
const { Dragger } = Upload;



export default function MyRightDrawer (props){

  //



  const onClose = () => {
    props.setOpen(false);
  };


  const props1 = {
    name: 'file',
    multiple: false,
    beforeUpload: (file) => {
      const isPDF = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isPDF) {
        message.error(`${file.name} is not a xlsx file`);
      }
      return isPDF || Upload.LIST_IGNORE;
    },
    action: 'https://www.skinmira.fr/api/upload/uploadskuxhs',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
        message.success(`${info.file.response} `);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);

      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      //console.log('Dropped files', e.dataTransfer.files);
    },
  };


    return(
      <Drawer title="上传商品" placement="right" onClose={onClose} open={props.open}>
      {//window.sessionStorage.printer?listprinter.length>0?listprinter[window.sessionStorage.printer].label:'无' :'无'
      }
        
  <Dragger {...props1}>
  <p className="ant-upload-text">上传小红书商品</p>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">点击或拖拽文件至此区域即可上传</p>
  </Dragger>
    </Drawer>
    )
}