import React  from 'react'
import { Menu } from 'antd';
import { HomeOutlined,
         AppstoreOutlined,
         UnorderedListOutlined,
         ShoppingOutlined ,
         GoldOutlined,
         DropboxOutlined,
         MoneyCollectOutlined ,
         LineChartOutlined,
         DatabaseOutlined ,
         EuroCircleOutlined ,
         AndroidOutlined ,
         TeamOutlined
 } from '@ant-design/icons';
import {Link} from "react-router-dom";
//import 'antd/dist/antd.css';

const Icons = {
  'HomeOutlined': <HomeOutlined />,
  'AppstoreOutlined':<AppstoreOutlined />,
  'UnorderedListOutlined':<UnorderedListOutlined />,
  'ShoppingOutlined':<ShoppingOutlined />,
  'GoldOutlined':<GoldOutlined />,
  'DropboxOutlined':<DropboxOutlined />,
  'MoneyCollectOutlined':<MoneyCollectOutlined />,
  'LineChartOutlined':<LineChartOutlined />,
  'DatabaseOutlined':<DatabaseOutlined />,
  'EuroCircleOutlined': <EuroCircleOutlined />,
  'TeamOutlined':<TeamOutlined />,
  'AndroidOutlined':<AndroidOutlined />
};

// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4', 'sub5', 'sub6','sub7','sub8','sub9','sub10','sub11','sub12'];

const CustomMenu = (props) => {
 

  const [openKeys, setOpenKeys] = React.useState([]);

  const onOpenChange = keys => {

    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);

    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    
    }
  };

  const renderMenuItem = ({key,  title,icon}) => {
    const MyIcon = Icons[icon];
    return (
      <Menu.Item key={key} icon={MyIcon}>
        <Link to={key}>
          <span>{title}</span>
        </Link>
      </Menu.Item>
    )
  }
  const renderSubMenu = ({key,  title, subs ,icon}) => {
    const MyIcon = Icons[icon];
    return (
      <Menu.SubMenu key={key} title={title} icon={MyIcon}> 
        {
          subs && subs.map(item => {
            return item.subs && item.subs.length > 0 ? renderSubMenu(item) : renderMenuItem(item)
          })
        }
      </Menu.SubMenu>
    )
  }

  return (
    <div>

    <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}  theme="dark" >
      {
          props.menus && props.menus.map(item => {
            return item.subs && item.subs.length > 0 ? renderSubMenu(item) : renderMenuItem(item)
          })
        }
    </Menu>
    </div>
  );
};

export default CustomMenu



/*import React from 'react'
import {Link,withRouter} from 'react-router-dom'
import {Menu} from 'antd'

//此组件的意义就是将数据抽离出来，通过传递数据去渲染

class CustomMenu extends React.Component {
  state = {
    openKeys: [],
    selectedKeys: []
  }

  componentDidMount() {
    // 防止页面刷新侧边栏又初始化了
    const pathname = this.props.location.pathname
    //获取当前所在的目录层级
    const rank = pathname.split('/')
    switch (rank.length) {
      case 2 :  //一级目录
        this.setState({
          selectedKeys: [pathname]
        })
        break;
      case 5 : //三级目录，要展开两个subMenu
        this.setState({
          selectedKeys: [pathname],
          openKeys: [rank.slice(0, 3).join('/'), rank.slice(0, 4).join('/')]
        })
        break;
      default :
        this.setState({
          selectedKeys: [pathname],
          openKeys: [pathname.substr(0, pathname.lastIndexOf('/'))]
        })
    }
  }

  componentWillReceiveProps(nextProps) {
    //当点击面包屑导航时，侧边栏要同步响应
    const pathname = nextProps.location.pathname
    if (this.props.location.pathname !== pathname) {
      this.setState({
        selectedKeys: [pathname],
      })
    }
  }

  onOpenChange = (openKeys) => {
    //此函数的作用只展开当前父级菜单（父级菜单下可能还有子菜单）
    if (openKeys.length === 0 || openKeys.length === 1) {
      this.setState({
        openKeys
      })
      return
    }

    //最新展开的菜单
    const latestOpenKey = openKeys[openKeys.length - 1]
    //判断最新展开的菜单是不是父级菜单，若是父级菜单就只展开一个，不是父级菜单就展开父级菜单和当前子菜单
    //因为我的子菜单的key包含了父级菜单，所以不用像官网的例子单独定义父级菜单数组，然后比较当前菜单在不在父级菜单数组里面。
    //只适用于3级菜单
    if (latestOpenKey.includes(openKeys[0])) {
      this.setState({
        openKeys
      })
    } else {
      this.setState({
        openKeys: [latestOpenKey]
      })
    }
  }

  renderMenuItem = ({key,  title,}) => {
    return (
      <Menu.Item key={key}>
        <Link to={key}>
          {icon && <Icon type={icon}/>}
          <span>{title}</span>
        </Link>
      </Menu.Item>
    )
  }
  renderSubMenu = ({key,  title, subs}) => {
    return (
      <Menu.SubMenu key={key} title={<span>{icon && <Icon type={icon}/>}<span>{title}</span></span>}>
        {
          subs && subs.map(item => {
            return item.subs && item.subs.length > 0 ? this.renderSubMenu(item) : this.renderMenuItem(item)
          })
        }
      </Menu.SubMenu>
    )
  }

  render() {
    const {openKeys, selectedKeys} = this.state
    return (
      <Menu
        onOpenChange={this.onOpenChange}
        onClick={({key}) => this.setState({selectedKeys: [key]})}
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        theme={this.props.theme ? this.props.theme : 'dark'}
        mode='inline'>
        {
          this.props.menus && this.props.menus.map(item => {
            return item.subs && item.subs.length > 0 ? this.renderSubMenu(item) : this.renderMenuItem(item)
          })
        }
      </Menu>
    )
  }
}

export default CustomMenu*/