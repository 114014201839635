import { message } from 'antd';
import axios from 'axios';
import { createBrowserHistory } from 'history';

if(!axios.defaults.headers.common['Authorization']){ axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')}
const history = createBrowserHistory();
const config = {
  headers: {
    "Content-Type": "application/json",
  }
};

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
            ApiService.showMessage('error', '登录超时，请重新登录');
          history.push("/admin-login");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          break;
          case 403:
            ApiService.showMessage('error', '错误请求 您没有权限');
            break;
            default:
              ApiService.showMessage('error', '错误');   
      }
    }
    return Promise.resolve(error);
  }
);
const baseUrl ="https://www.skinmira.fr/api"
const ApiService = {
  post: (url, data) => {

    return axios.post(baseUrl + url, data, config)
      .then(res => Promise.resolve(res))

  },
  postimg: (url, data) => {
    return axios.post(baseUrl + url, data)
      .then(res => Promise.resolve(res))

  },
  get: (url) => {
    return axios.get(baseUrl + url, config)
      .then(res => Promise.resolve(res));
  },
  showMessage: (type, content) => {
    
    switch (type) {
      case 'success':
        message.success(content);
        break;
      case 'error':
        message.error(content);
        break;
      case 'info':
        message.info(content);
        break;
      // 可以添加更多类型的消息，如 warning 等
      default:
        break;
    }
  }



};

export default ApiService;