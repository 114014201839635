import React ,{useState} from 'react'
import { Badge, Dropdown, Menu,Button,Row,Col ,Avatar } from 'antd'
import MyRightDrawer from '../MyRightDrawer'
import MyimportOrder from '../MyRightDrawer/importOrder'
import MyimportWaybilljd from '../MyRightDrawer/importWaybilljd'
import MyimportSku from '../MyRightDrawer/importSku'
import MyimportTrackingjd from '../MyRightDrawer/importOrderjdTrackingNo'
import MyimportstandardProduct from '../MyRightDrawer/importStandardProduct'
//import { inject, observer } from 'mobx-react'MyimportSku
//import { Link} from 'react-router-dom'
import UserProfile from '../../utils/Session'
import { NotificationOutlined ,
  MenuFoldOutlined ,
  MenuUnfoldOutlined,UserOutlined } from '@ant-design/icons';

export default function HeaderBar (props){
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open21, setOpen21] = useState(false);
  const [visible, setVisible] = useState(false);
  
const menu = (
  <Menu className='menu'>
    <Menu.ItemGroup title='用户中心' className='menu-group'>
      <Menu.Item key ='1'>你好 - {UserProfile.getName()} </Menu.Item>
      <Menu.Item  key ='2'>个人信息</Menu.Item>
      <Menu.Item  key ='3'><a href ="/admin-login" onClick={()=>{localStorage.setItem( 'token', null)
    UserProfile.setLogin(false)
    } }> <span >退出登录</span></a> </Menu.Item>
    </Menu.ItemGroup>
    <Menu.ItemGroup title='京东导入' className='menu-group'>
      <Menu.Item  key ='4'onClick={()=>setOpen1(true)}>导入京东订单</Menu.Item>
      <Menu.Item  key ='5'onClick={()=>setOpen2(true)}>导入京东面单</Menu.Item>
      <Menu.Item  key ='8'onClick={()=>setOpen4(true)}>导入京东运单号</Menu.Item>
    </Menu.ItemGroup>
    <Menu.ItemGroup title='天猫导入' className='menu-group'>
      <Menu.Item  key ='21'onClick={()=>setOpen21(true)}>导入天猫标品</Menu.Item>
    </Menu.ItemGroup>
    <Menu.ItemGroup title='设置中心' className='menu-group'>
      <Menu.Item  key ='6'>个人设置</Menu.Item>
      <Menu.Item   key ='7' onClick={()=>setOpen(true)}>系统设置</Menu.Item>
    </Menu.ItemGroup>
  </Menu>
)

  const toggle = () => {
    props.onToggle()

  }//

    return (
      <>
      <MyRightDrawer open={open} setOpen={setOpen} ></MyRightDrawer>
      <MyimportOrder open={open1} setOpen={setOpen1} ></MyimportOrder>
      <MyimportWaybilljd open={open2} setOpen={setOpen2} ></MyimportWaybilljd>
      <MyimportSku open={open3} setOpen={setOpen3} ></MyimportSku>
      <MyimportTrackingjd open={open4} setOpen={setOpen4} ></MyimportTrackingjd>
      <MyimportstandardProduct open={open21} setOpen={setOpen21} ></MyimportstandardProduct>
      <Row>
        <Col span={2}>
        <Button type="primary" className='trigger' onClick={toggle} style={{ marginBottom: 16 }}>
          {React.createElement(props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
        </Button>
        </Col>
        <Col span={20}></Col>
        <Col span={1}>
        <Badge count={0} overflowCount={99} style={{marginRight: -17}}>
              <NotificationOutlined />
              </Badge>
        </Col>
        <Col span={1}>
        <Dropdown overlay={menu} placement="bottomCenter">
        <Avatar size="large" icon={<UserOutlined />} onClick={()=>setVisible(true)}/>
      </Dropdown>
        </Col>
      </Row>
      </>
    )
  }

/*
      <div >

 
        <div style={{lineHeight: '64px', float: 'right'}}>
          <ul className='header-ul'>
            
            <li onClick={() => setCount(0)}>
              <Badge count={10} overflowCount={99} style={{marginRight: -17}}>
              <NotificationOutlined />
              </Badge>
            </li>
            <li>
             {login}
            </li>
          </ul>
        </div>

      </div> 
id='headerbar'
export default HeaderBar
*/
