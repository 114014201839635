import React,{useEffect,useState} from 'react'
import {Layout} from 'antd'
import { Outlet} from "react-router-dom";
import SiderNav from '../../components/SiderNav'
//import ContentMain from '../../components/ContentMain'
import HeaderBar from '../../components/HeaderBar'

const {Sider, Header, Content} = Layout


function Index (){
  const [collapsed, setcollapsed] = useState(false);



    // 设置Sider的minHeight可以使左右自适应对齐
    return (
      <div id='page'>
        <Layout>
          <Sider 
                  collapsible ={true}
                  collapsedWidth ={80}
                 trigger={null}
                 collapsed={collapsed}
                 >
            <SiderNav/>
          </Sider>
          <Layout>
          <Header style={{background: '#fff', padding: '0 8px'}}>
              <HeaderBar collapsed={collapsed} onToggle={()=>setcollapsed(!collapsed)} />
            </Header>
            <Content>
            <Outlet />
            </Content>
            
          </Layout>
        </Layout>
      </div>
    );
  
}
export default Index
/*collapsible
<Footer style={{textAlign: 'center'}}>React-Admin ©2018 Created by 137596665@qq.com <a target='_blank' href='https://github.com/zhangZhiHao1996/react-admin-master'>github地址</a></Footer>
            <Header style={{background: '#fff', padding: '0 16px'}}>
              <HeaderBar collapsed={this.state.collapsed} onToggle={this.toggle}/>
            </Header>
            <Content>
              <ContentMain/>
            </Content>
*/