import  { useState   } from "react";
import { Drawer ,Select} from "antd";
import { getLodop } from '../../utils/LodopFuncs'


export default function MyRightDrawer (props){
  const [listprinter, setListprinter] = useState( []);

  //


const getlistprinter = ()=>{
  let LODOP = getLodop();

    let num = LODOP.GET_PRINTER_COUNT()
    let pp = []
    for (var i = 0 ;i < num;i++){
     pp.push(
      {
        value: `${i}`,
        label: ` ${LODOP.GET_PRINTER_NAME(i)} `,
    },
     )

    }
    setListprinter(pp)

  }

  const onClose = () => {
    props.setOpen(false);
  };
  const onChange = (value) =>{
   
        window.sessionStorage.printer = value
        window.localStorage.printer = value
  }




    return(
      <Drawer title="系统设置" placement="right" onClose={onClose} open={props.open}>
      {//window.sessionStorage.printer?listprinter.length>0?listprinter[window.sessionStorage.printer].label:'无' :'无'
      }
    <Select style={ { width:'100%'}}
    onClick={getlistprinter}
      onChange={onChange}
      options={listprinter}
    />
    </Drawer>
    )
}