import React, { useState } from 'react';
import { Form, Input, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ApiService from '../../utils/axios';

const RegisterForm = (props) => {
  const [count, setCount] = useState(-1);
  const [passwordError, setPasswordError] = useState('');

  const GobackLogin = () => {
    props.switchShowBox('login');
  };

  const validatePassword = (_, value) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*-+=~.,:;/\\|_?<>()\[\]{}])[A-Za-z\d!@#$%^&*-+=~.,:;/\\|_?<>()\[\]{}]{8,}$/;// 至少8个字符，包括大写字母、小写字母、数字和特殊符号


    if (!value || regex.test(value)) {
      
      setPasswordError('');
      return Promise.resolve();
    }
    setPasswordError('密码必须包含至少一个大写字母、一个小写字母、一个数字和一个特殊符号');
    return Promise.reject();
  };
  const validateEmail = (_, value) => {
    const regex =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


    if (!value || regex.test(value)) {
      
      return Promise.resolve();
    }
    return Promise.reject('请输入有效的邮箱地址');
  };

  const finish = (value) => {
    
    ApiService.post('/user/inscrit',value).then(res => {
      if(res.data){
        if(res.data.affectedRows > 0){
          message.success("注册成功");
        }
      }
    }).catch((error)=>{
      
    })
  };

  return (
    <div className={props.className}>
      <h3 className='title'>注册</h3>
      <Form onFinish={finish}
      autoComplete="off"
      >
        
        <Form.Item
          name='email1'
          style={{ marginBottom: 10 }}
          rules={[
            {
              required: true,
              message: '请输入名字',
            },
          ]}
        >
          <Input
            onFocus={() => setCount(0)}
            onBlur={() => setCount(-1)}
            maxLength={30}
            placeholder='名字'
            addonBefore={<span ><UserOutlined style={count === 0 ? styles.focus : {}}/></span>}
          />
        </Form.Item>
        <Form.Item
          name='user'
          style={{ marginBottom: 10 }}
          rules={[
            {
              required: true,
              message: '请输入邮箱',
            },
            {
              validator: validateEmail,
            },
          ]}
        >
          <Input
            onFocus={() => setCount(1)}
            onBlur={() => setCount(-1)}
            maxLength={30}
            placeholder='邮箱'
            addonBefore={<span ><UserOutlined style={count === 1 ? styles.focus : {}}/></span>}
          />
        </Form.Item>
        <Form.Item
          name='psw'
          rules={[
            {
              validator: validatePassword,
            }
              


          ]}
          validateStatus={passwordError ? 'error' : ''}
          help={passwordError}
          style={{ marginBottom: 10 }}
        >
          <Input
            onFocus={() => setCount(2)}
            onBlur={() => setCount(-1)}
            type='password'
            maxLength={16}
            placeholder='密码'
            addonBefore={<span ><LockOutlined style={count === 2 ? styles.focus : {}}/></span>}
          />
        </Form.Item>
        <Form.Item
          name='psw1'
          dependencies={['psw']}
          rules={[
            {
              required: true,
              message: '请确认密码',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('psw') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('两次密码不一致'));
              },
            }),
          ]}
          style={{ marginBottom: 10 }}
        >
          <Input
            onFocus={() => setCount(3)}
            onBlur={() => setCount(-1)}
            type='password'
            maxLength={16}
            placeholder='确认密码'
            addonBefore={<span ><LockOutlined style={count === 3 ? styles.focus : {}}/></span>}
          />
        </Form.Item>
        <div className='bottom'>
          <input className='loginBtn' type='submit' value='注册'/>
          <span className='registerBtn' onClick={GobackLogin}>返回登录</span>
        </div>
      </Form>
      <div className='footer'>
        <div>欢迎登陆后台管理系统</div>
      </div>
    </div>
  );
};

const styles = {
  focus: {
    width: '20px',
    opacity: 1,
  },
};

export default RegisterForm;