import React, { useState } from 'react'
import axios from 'axios';
//import { randomNum, calculateWidth } from '../../utils/utils'
//import { withRouter } from 'react-router-dom'
//import { inject, observer } from 'mobx-react/index'
import { Form, Input,Button, message } from 'antd' //, Row, Col
//import PromptBox from '../../components/PromptBox'
import {UserOutlined,LockOutlined} from '@ant-design/icons';

import ApiService from '../../utils/axios';

import UserProfile from '../../utils/Session'
//@withRouter @inject('appStore') @observer @Form.create()
import {useNavigate} from 'react-router-dom';
function LoginForm(props) {

  const [count, setCount] = useState(-1);
  let navigation = useNavigate();







  const loginSubmit = (value) => {
    setCount(count - 1)
    const data = {
      'email':value.email,
      'password':value.password,
    }
    ApiService.post("/user/login",data).then(res => {
    
      if (res.data) {
        if(res.data[0].user === 1 &&  res.data[0].login !== 1){
          message.info("账号等待管理员验证通过")
          return
        }
        
        if(res.data[0].user === 1){

          getusrinfo(data)
          UserProfile.setLogin(true)
          UserProfile.setFirstlogin(true)
          navigation("/home")
        
        }
        
        else{
          message.info("账号不存在 或者密码错误")
        }
      }
    })




  }
  const getusrinfo = (data) => {

    ApiService.post("/user/usrinfo",data).then(res => {
    
      if (res.data) {
        localStorage.setItem( 'token', res.data.token)
        // 处理响应数据
        UserProfile.setLogin(true)
        UserProfile.setFirstlogin(true)
        UserProfile.setName(res.data?.results?.[0]?.name)
        axios.defaults.headers.common['Authorization'] = res.data.token;
    
        localStorage.setItem('permissions',JSON.stringify(res.data.permissions))  
       
      
      }
    })
  }

  const Register = () => {

    props.switchShowBox('register')

  
  }
  const Forgot = () => {

    props.switchShowBox('forgot')

  
  }
    return (
      <div className={props.className}>
        <h3 className='title'>登录/login</h3>
        <Form onFinish={loginSubmit}>
          <Form.Item name={'email'} style={{marginBottom:30}} >
            <Input
              onFocus={() => setCount(0)}
              onBlur={() => setCount(-1)}
              maxLength={30}
              placeholder='用户名/邮箱/email'
              addonBefore={<span ><UserOutlined style={count === 0 ? styles.focus : {}}/> </span> }  />
          </Form.Item>
          <Form.Item name={'password'} style={{marginBottom:30}} >
            <Input
              onFocus={() => setCount(1)}
              onBlur={() => setCount(-1)}
              type='password'
              maxLength={16}
              placeholder='密码/password'
              addonBefore= { <span ><LockOutlined style={count === 1 ? styles.focus : {}} />   </span>} />
          </Form.Item>
          <div className='bottom'>
            <Button className='loginBtn' htmlType="submit" >登陆/login</Button>
            <span className='registerBtn' onClick={Register}>注册</span>
          </div>
        </Form>
        <div className='footer'>
          <div>欢迎登陆后台管理系统</div>
          <span className='registerBtn' onClick={Forgot}>忘记密码</span>
        </div>
      </div>
    )
  }


const styles = {
  focus: {
    width: '20px',
    opacity: 1
  },
}

export default LoginForm

/*
loginSubmit = (e) => {
    e.preventDefault()
    this.setState({
      focusItem: -1
    })
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // 表单登录时，若验证码长度小于4则不会验证，所以我们这里要手动验证一次，线上的未修复
        if (this.state.code.toUpperCase() !== values.verification.toUpperCase()) {
          this.props.form.setFields({
            verification: {
              value: values.verification,
              errors: [new Error('验证码错误')]
            }
          })
          return
        }

        const users = this.props.appStore.users
        // 检测用户名是否存在
        const result = users.find(item => item.username === values.username)
        if (!result) {
          this.props.form.setFields({
            username: {
              value: values.username,
              errors: [new Error('用户名不存在')]
            }
          })
          return
        } else {
          //检测密码是否错误
          if (result.password !== values.password) {
            this.props.form.setFields({
              password: {
                value: values.password,
                errors: [new Error('密码错误')]
              }
            })
            return
          }
        }

        this.props.appStore.toggleLogin(true, {username: values.username})

        const {from} = this.props.location.state || {from: {pathname: '/'}}
        this.props.history.push(from)
      }
    })
  }
        <Form onSubmit={this.loginSubmit}>
          <Form.Item help={getFieldsError('username') &&
          <PromptBox info={getFieldsError('username')} width={calculateWidth(getFieldsError('username'))}/>} >
            {('username', {
              rules: [{required: true, message: '请输入用户名'}]
            })(
              <Input
                onFocus={() => this.setState({focusItem: 0})}
                onBlur={() => this.setState({focusItem: -1})}
                maxLength={16}
                placeholder='用户名'
                addonBefore={<span className='iconfont icon-User' style={focusItem === 0 ? styles.focus : {}}/>}/>
            )}
          </Form.Item>
          <Form.Item help={getFieldsError('password') &&
          <PromptBox info={getFieldsError('password')} width={calculateWidth(getFieldsError('password'))}/>} >
            {('password', {
              rules: [{required: true, message: '请输入密码'}]
            })(
              <Input
                onFocus={() => this.setState({focusItem: 1})}
                onBlur={() => this.setState({focusItem: -1})}
                type='password'
                maxLength={16}
                placeholder='密码'
                addonBefore={<span className='iconfont icon-suo1' style={focusItem === 1 ? styles.focus : {}}/>}/>
            )}
          </Form.Item>
          <Form.Item help={getFieldsError('verification') &&
          <PromptBox info={getFieldsError('verification')} width={calculateWidth(getFieldsError('verification'))}/>} noStyle>
            {('verification', {
              validateFirst: true,
              rules: [
                {required: true, message: '请输入验证码'},
                {
                  validator: (rule, value, callback) => {
                    if (value.length >= 4 && code.toUpperCase() !== value.toUpperCase()) {
                      callback('验证码错误')
                    }
                    callback()
                  }
                }
              ]
            })(
              <Row>
                <Col span={15}>
                  <Input
                    onFocus={() => this.setState({focusItem: 2})}
                    onBlur={() => this.setState({focusItem: -1})}
                    maxLength={4}
                    placeholder='验证码'
                    addonBefore={<span className='iconfont icon-securityCode-b'
                                       style={focusItem === 2 ? styles.focus : {}}/>}/>
                </Col>
                <Col span={9}>
                  <canvas onClick={this.createCode} width="80" height='39' ref={el => this.canvas = el}/>
                </Col>
              </Row>
            )}
          </Form.Item>
          <div className='bottom'>
            <input className='loginBtn' type="submit" value='登录'/>
            <span className='registerBtn' onClick={this.register}>注册</span>
          </div>
        </Form>
*/
  /**
   *   componentDidMount () {
    this.createCode()
  }
   * 生成验证码
   *   createCode = () => {
    const ctx = this.canvas.getContext('2d')
    const chars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    let code = ''
    ctx.clearRect(0, 0, 80, 39)
    for (let i = 0; i < 4; i++) {
      const char = chars[randomNum(0, 57)]
      code += char
      ctx.font = randomNum(20, 25) + 'px SimHei'  //设置字体随机大小
      ctx.fillStyle = '#D3D7F7'
      ctx.textBaseline = 'middle'
      ctx.shadowOffsetX = randomNum(-3, 3)
      ctx.shadowOffsetY = randomNum(-3, 3)
      ctx.shadowBlur = randomNum(-3, 3)
      ctx.shadowColor = 'rgba(0, 0, 0, 0.3)'
      let x = 80 / 5 * (i + 1)
      let y = 39 / 2
      let deg = randomNum(-25, 25)
      设置旋转角度和坐标原点
      ctx.translate(x, y)
      ctx.rotate(deg * Math.PI / 180)
      ctx.fillText(char, 0, 0)
      恢复旋转角度和坐标原点
      ctx.rotate(-deg * Math.PI / 180)
      ctx.translate(-x, -y)
    }
    this.setState({
      code
    })
  }
   */