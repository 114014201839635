var name =""
var UserProfile = (function() {


  var getName = function() {
    return window.sessionStorage.name;    
  };

  var setName = function(name1) {

    window.sessionStorage.name=name1
  };
  var getEmail = function() {
    return window.sessionStorage.email;    
  };

  var setEmail = function(email) {
 
    window.sessionStorage.email=email
  };
  var getExpires = function() {
    return  window.sessionStorage.date_Expires;    
  };

  var setExpires = function(Expires) {

    window.sessionStorage.date_Expires=Expires
  };
  var getLogin = function() {
    return window.sessionStorage.login;    
  };

  var setLogin = function(Login) {
    
    window.sessionStorage.login=Login
  };
  var getFirstlogin = function() {
    return window.sessionStorage.Firstlogin;    
  };

  var setFirstlogin = function(Firstlogin) {
    
    window.sessionStorage.Firstlogin=Firstlogin
  };

  var getAdmin = function() {
    return  window.sessionStorage.admin;  
  };

  var setAdmin = function(admin) {
   
    window.sessionStorage.admin=admin
  };
  var getDirector = function() {
    return window.sessionStorage.director;    
  };

  var setDirector = function(director) {  
    window.sessionStorage.director=director
  };
  var getWorkers = function() {
    return window.sessionStorage.workers;    
  };

  var setWorkers = function(workers) {  
    window.sessionStorage.workers=workers
  };
  var getPurchasher = function() {
    return window.sessionStorage.purchasher;    
  };

  var setPurchasher = function(purchasher) {  
    window.sessionStorage.purchasher=purchasher
  };
  var getOrderer = function() {
    return window.sessionStorage.orderer;    
  };

  var setOrderer = function(orderer) {  
    window.sessionStorage.orderer=orderer
  };
  return {
    getName: getName,
    setName: setName,
    getEmail: getEmail,
    setEmail: setEmail,
    getExpires: getExpires,
    setExpires: setExpires,
    getLogin:getLogin,
    setLogin:setLogin,
    getFirstlogin:getFirstlogin,
    setFirstlogin:setFirstlogin,
    getAdmin: getAdmin,
    setAdmin: setAdmin,
    getDirector:getDirector,
    setDirector:setDirector,
    getWorkers:getWorkers,
    setWorkers:setWorkers,
    getPurchasher:getPurchasher,
    setPurchasher:setPurchasher,
    getOrderer:getOrderer,
    setOrderer:setOrderer,
  }

})();

export default UserProfile;



