
export const getPermissions = () => {
  const permissionsString = localStorage.getItem('permissions');
  let parsedPermissions = {};

  try {
    if (permissionsString) {
      parsedPermissions = JSON.parse(permissionsString);
    }
  } catch (error) {
    // 如果解析失败，可以在这里处理错误
    console.error('Error parsing permissions:', error);
  }

  return parsedPermissions;
};
  
  // 检查用户是否有权限访问特定页面
  export const hasPermission = (pageName) => {
    const permissions = getPermissions();

    if(permissions) return permissions.hasOwnProperty(pageName) && permissions[pageName] === 1;
    else return false
  };