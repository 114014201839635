import React , { useState, useEffect } from 'react'
//import BGParticle from '../../utils/BGParticle'
//import { notification } from 'antd'
import './style.css'
//import { withRouter } from 'react-router-dom'
//import { inject, observer } from 'mobx-react/index'
//import Loading2 from '../../components/Loading2'
//import {preloadingImages} from '../../utils/utils'
//import 'animate.css'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import ForgotForm from './ForgotForm'



const url1 = ''
//const imgs = []
/*function initUsers() { //QQͼƬ20220209131301.gif
  const localUsers = localStorage['users']?JSON.parse(localStorage['users']):[]
  this.users = [{username: 'admin', password: 'admin'},...localUsers]
}*/



//@withRouter @inject('appStore') @observer

  function Login() {
    const [showBox, setshowBox] = useState('login');

    const [loading, setloading] = useState(false);


  useEffect(() => {
    initPage()

  }, []);

  /*componentDidMount () {
    const isLogin = this.props.appStore
    if(isLogin){
      this.props.history.go(1)     //当浏览器用后退按钮回到登录页时，判断登录页是否登录，是登录就重定向上个页面
      // this.props.appStore.toggleLogin(false) //也可以设置退出登录
    }
    this.initPage()
    preloadingImages(imgs)  //预加载下一个页面的图片，预加载了第二次为什么还会去请求图片资源？
  }

  componentWillUnmount () {
    this.particle && this.particle.destory()
    notification.destroy()
  }*/
  //载入页面时的一些处理
  const initPage = () => {
    setloading(true)


    setTimeout(() => {
      setloading(false)
    }, 2000);
         // this.particle && this.particle.destory()
    //initUsers()
    /*loadImageAsync(url1).then(url1=>{


      setloading(false)
      
      
      
    }).then(()=>{
      //为什么写在then里？id为backgroundBox的DOM元素是在loading为false时才有，而上面的setState可能是异步的，必须等到setState执行完成后才去获取dom
     // const particle = new BGParticle('backgroundBox')
      //particle.init()
      notification.open({
        message:<ul><li>初始账号：admin</li><li>初始密码：admin</li></ul>,
        duration:0,
        className:'login-notification'
      })
    })*/
  }
  //切换showbox
  const switchShowBox = (box) => {
    setshowBox(box)
  }

  //登录的背景图太大，等载入完后再显示，实际上是图片预加载，
  /*const loadImageAsync = (url) =>{
    return new Promise(function(resolve, reject) {
      const image = new Image();
      image.onload = function() {
        resolve(url);
      };
      image.onerror = function() {
        console.log('图片载入错误')
      };
      image.src = url;
    });
  }*/


    return (
      <div id='login-page'>
        {
          loading ?
            <div>
              <h3 style={styles.loadingTitle} className='animated bounceInLeft'>载入中...</h3>
              
            </div>:
            <div>
              <div id='backgroundBox' style={styles.backgroundBox}/>
              <div className='container'>
              <LoginForm
                  className={showBox === 'login' ? 'box showBox' : 'box hiddenBox'}
                  switchShowBox={switchShowBox}/>
                <RegisterForm
                  className={showBox === 'register' ? 'box showBox' : 'box hiddenBox'}
                  switchShowBox={switchShowBox}/>
                <ForgotForm
                  className={showBox === 'forgot' ? 'box showBox' : 'box hiddenBox'}
                  switchShowBox={switchShowBox}/>
               
              </div>
            </div>
        }
      </div>
    )
  }


const styles = {
  backgroundBox: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${url1})`,
    backgroundSize: 'cover',
    transition:'all .5s'
  },
  focus: {
    // transform: 'scale(0.7)',
    width: '20px',
    opacity: 1
  },
  loadingBox:{
    position:'fixed',
    top:'50%',
    left:'50%',
    transform:'translate(-50%,-50%)'
  },
  loadingTitle:{
    position:'fixed',
    top:'50%',
    left:'50%',
    marginLeft: -45,
    marginTop: -18,
    color:'#000',
    fontWeight:500,
    fontSize:24
  },
}

export default Login
