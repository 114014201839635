import React,{useEffect,useState} from 'react'
import {message, Button,Form,Input} from 'antd'
import ApiService from '../../utils/axios';

export default function Modalreset_password(props){
    const formRef = React.createRef()
    const [loading, setloading] = useState(false);

    const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 6,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 14,
          },
        },
      };


    useEffect(() => {
       
      },[props]);


      const onFinish = (fieldsValue) => {
        const data = new URLSearchParams()

        setloading(true)
        data.append('command',fieldsValue.psw)
        data.append('admin_memo',fieldsValue.psw1)


        ApiService.post('/UpdateAdmin_memo',data
        ).then(res => { 
            if(res !== undefined){
          setTimeout(()=>setloading(false)
          ,2000)
    
          setTimeout(()=>affectedRows(res.data.affectedRows)
          ,2000)
          setTimeout(()=>props.getRemoteData()
          ,2500)
          setTimeout(()=>props.setvisibility(false)
          ,2500)
        }
        })
    
      }
      
      const affectedRows = (affectedRows)=>{
        if(affectedRows >0){
          message.success('成功', 5)
    
        }
        else{message.error('失败');}
      }
    return(
        <>
        
        <Form {...formItemLayout} onFinish={onFinish} ref={formRef}  autoComplete="off">
              <Form.Item
              name="psw"
                label="密码"
                rules={[
                  {
                    required: true,
                    min:6,
                    message: '密码最少6位',
                  },
                ]}>
                <Input.Password />
              </Form.Item>
              <Form.Item
              name="psw1"
                label="确认密码"
                dependencies={['psw']}
                rules={[
                  {
                    required: true,
                    message: '密码最少6位',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('psw') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('两次密码不一样'));
                    },
                  }),
                ]}>
                <Input.Password />
              </Form.Item>
              <Form.Item 
                      wrapperCol={{
                        xs: { span: 24, offset: 0 },
                        sm: { span: 16, offset: 16 },
                      }}>
              <Button type="primary" htmlType="submit" loading={loading} >
          提交
        </Button>
        </Form.Item>
              </Form>
                    </>
    )
}