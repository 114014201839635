import React, { useState, useEffect } from 'react'
import { Form, Input, Button, message,Modal } from 'antd'
import {UserOutlined,LockOutlined} from '@ant-design/icons';

import ModalresetPassword from './modal';
//@inject('appStore') @observer @Form.create()

  const ForgotForm = (props) => {
    const inputRef = React.useRef()
  const [count, setCount] = useState(-1);
  const [counttime, setCounttime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [visibility, setvisibility] = useState(false);
  const GobackLogin = () => {
      props.switchShowBox('login')
   };
   useEffect(() => {
     const newTimer = setInterval(() => {
       if (counttime > 0) {
        setCounttime((c) => c - 1);
       }else{
        setLoading(false)
       }
     }, 1000);
     return () => clearInterval(newTimer);
   }, [counttime]);
 
   const getpassword = () => {
/*
    if(inputRef.current.state.value){
      const data = new URLSearchParams()
      data.append('username',inputRef.current.state.value)
      userpost('/getpassword',data
      ).then(res => {
        if(res !== undefined){
         
      }
      })
      setCounttime(60)
      setLoading(true)
      message.success("已发送验证码到此邮箱 请勿多次发送",10)
    }else{message.warning("用户名不能为空")}
*/
 };
 const resetpassword = (value)=>{
  /*
  const data = new URLSearchParams()
  data.append('username',inputRef.current.state.value)
  data.append('verify',value.verify)
  userpost('/preresetpassword',data
  ).then(res => {
    if(res !== undefined){
      if(res.data.date.verify ==='ok'){
       setvisibility(true)
     }
  }
  })*/
}
const handleCancel = () => {
  setvisibility(false);
};

    return (

      <div className={props.className}>
              <Modal
      title='修改'
      visible={visibility}
      onCancel={handleCancel}
      footer={null}
      centered={true}
    >
      <ModalresetPassword  visivilite={visibility} setvisibility={setvisibility} ></ModalresetPassword>
    </Modal>
        <h3 className='title'>找回密码</h3>
        <Form onFinish={resetpassword}>
          <Form.Item style={{marginBottom:20}}>
              <Input.Password  ref={inputRef}
                onFocus={() => setCount(0)}
                onBlur={() => setCount(-1)}
                maxLength={30}
                placeholder='用户名/邮箱'
                addonBefore={<span ><UserOutlined style={count === 0 ? styles.focus : {}}/> </span> }  />
          </Form.Item>
          <Form.Item name={'verify'} style={{marginBottom:20}}>
              <Input
                onFocus={() => setCount(1)}
                onBlur={() => setCount(-1)}
                //type='password'
                maxLength={16}
                placeholder='验证码'
                addonBefore= { <span ><LockOutlined style={count === 1 ? styles.focus : {}} />   </span>} 
                addonAfter={<span ><Button type="primary" onClick={getpassword} loading={loading}>获取验证码{counttime?counttime:''} </Button>  </span>}
                />
            
          </Form.Item>

          <div className='bottom'>
            <input className='loginBtn' type="submit" value='确定'/>
            <span className='registerBtn' onClick={GobackLogin}>返回登录</span>
          </div>
        </Form>
        <div className='footer'>
          <div>欢迎登陆后台管理系统</div>
        </div>
      </div>
    )
  }


const styles = {
  focus: {
    width: '20px',
    opacity: 1
  },
}

export default ForgotForm

/*
    const {getFieldDecorator, getFieldError, getFieldValue} = this.props.form
    const {focusItem} = this.state

  registerSubmit = (e) => {
    e.preventDefault()
    this.setState({
      focusItem: -1
    })
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const users = this.props.appStore.users
        // 检测用户名是否存在
        const result = users.find(item => item.username === values.registerUsername)
        if (result) {
          this.props.form.setFields({
            registerUsername: {
              value: values.registerUsername,
              errors: [new Error('用户名已存在')]
            }
          })
          return
        }

        const obj = [...this.props.appStore.users, {
          username: values.registerUsername,
          password: values.registerPassword
        }]
        localStorage.setItem('users', JSON.stringify(obj))
        this.props.appStore.initUsers()
        message.success('注册成功')
      }
    })
  }

    gobackLogin = () => {
    this.props.switchShowBox('login')
    setTimeout(() => this.props.form.resetFields(), 500)
  }

<Form onSubmit={this.registerSubmit}>
          <Form.Item help={getFieldError('registerUsername') && <PromptBox info={getFieldError('registerUsername')}
                                                                           width={calculateWidth(getFieldError('registerUsername'))}/>}>
            {getFieldDecorator('registerUsername', {
              validateFirst: true,
              rules: [
                {required: true, message: '用户名不能为空'},
                {pattern: '^[^ ]+$', message: '不能输入空格'},
              ]
            })(
              <Input
                onFocus={() => this.setState({focusItem: 0})}
                onBlur={() => this.setState({focusItem: -1})}
                maxLength={16}
                placeholder='用户名'
                addonBefore={<span className='iconfont icon-User' style={focusItem === 0 ? styles.focus : {}}/>}/>
            )}
          </Form.Item>
          <Form.Item help={getFieldError('registerPassword') && <PromptBox info={getFieldError('registerPassword')}
                                                                           width={calculateWidth(getFieldError('registerPassword'))}/>}>
            {getFieldDecorator('registerPassword', {
              validateFirst: true,
              rules: [
                {required: true, message: '密码不能为空'},
                {pattern: '^[^ ]+$', message: '密码不能有空格'}
              ]
            })(
              <Input
                onFocus={() => this.setState({focusItem: 1})}
                onBlur={() => this.setState({focusItem: -1})}
                type='password'
                maxLength={16}
                placeholder='密码'
                addonBefore={<span className='iconfont icon-suo1' style={focusItem === 1 ? styles.focus : {}}/>}/>
            )}
          </Form.Item>
          <Form.Item help={getFieldError('confirmPassword') && <PromptBox info={getFieldError('confirmPassword')}
                                                                          width={calculateWidth(getFieldError('confirmPassword'))}/>}>
            {getFieldDecorator('confirmPassword', {
              validateFirst: true,
              rules: [
                {required: true, message: '请确认密码'},
                {
                  validator: (rule, value, callback) => {
                    if (value && value !== getFieldValue('registerPassword')) {
                      callback('两次输入不一致！')
                    }
                    callback()
                  }
                },
              ]
            })(
              <Input
                onFocus={() => this.setState({focusItem: 2})}
                onBlur={() => this.setState({focusItem: -1})}
                type='password'
                maxLength={16}
                placeholder='确认密码'
                addonBefore={<span className='iconfont icon-suo1' style={focusItem === 2 ? styles.focus : {}}/>}/>
            )}
          </Form.Item>
          <div className='bottom'>
            <input className='loginBtn' type="submit" value='注册'/>
            <span className='registerBtn' onClick={this.gobackLogin}>返回登录</span>
          </div>
        </Form>
*/